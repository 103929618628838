import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import './assets/styles/global.css';
import './assets/styles/tailwind.css';
import { auth0Config } from './auth0-config';
<link href="https://fonts.googleapis.com/css2?family=Inspiration&family=Kumbh+Sans:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;600&family=Raleway:wght@400;700&display=swap" rel="stylesheet"></link>


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      redirectUri={auth0Config.redirectUri}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);