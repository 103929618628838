// src/components/common/TopRight.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ShoppingCartIcon, UserIcon } from '@heroicons/react/24/outline';
import { useAuth0 } from '@auth0/auth0-react';
import CartMenu from './../Cart/CartMenu';
import { useCart } from '../../contexts/CartContext';

function TopRight() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const { state } = useCart();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleCart = () => setIsCartOpen(!isCartOpen);

  const cartItemsCount = state.items.reduce((total, item) => total + item.quantity, 0);

  return (
    <div className="relative">
      {/* Desktop version */}
      <div className="hidden md:flex items-center justify-between w-80 bg-light-green-50 p-3 rounded-lg shadow-[0_4px_20px_rgba(0,0,0,0.2)] hover:shadow-[0_6px_24px_rgba(0,0,0,0.25)] transition-shadow duration-300">
        {isAuthenticated ? (
          <Link to="/profile" className="flex-grow mr-4">
            <button className="w-full bg-green-button-color text-white px-6 py-3 rounded-lg hover:bg-opacity-90 transition-all duration-200 text-lg font-bold shadow-md hover:shadow-lg">
              Min Profil
            </button>
          </Link>
        ) : (
          <button 
            onClick={() => loginWithRedirect()} 
            className="flex-grow mr-4 bg-green-button-color text-white px-6 py-3 rounded-lg hover:bg-opacity-90 transition-all duration-200 text-lg font-bold shadow-md hover:shadow-lg"
          >
            Login
          </button>
        )}
        <button onClick={toggleCart} className="relative bg-white p-3 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200">
          <ShoppingCartIcon className="h-8 w-8 text-primary-green" />
          {cartItemsCount > 0 && (
            <span className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
              {cartItemsCount}
            </span>
          )}
        </button>
      </div>

      {/* Mobile version */}
      <div className="md:hidden flex items-center space-x-2">
        <button onClick={toggleMenu} className="relative">
          <div className="absolute inset-0 bg-light-green-50 rounded-full scale-125"></div>
          <div className="relative bg-white p-2 rounded-full shadow-md">
            <UserIcon className="h-6 w-6 text-primary-green" />
          </div>
        </button>
        <button onClick={toggleCart} className="relative">
          <div className="absolute inset-0 bg-light-green-50 rounded-full scale-125"></div>
          <div className="relative bg-white p-2 rounded-full shadow-md">
            <ShoppingCartIcon className="h-6 w-6 text-primary-green" />
            {cartItemsCount > 0 && (
              <span className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs">
                {cartItemsCount}
              </span>
            )}
          </div>
        </button>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
          {isAuthenticated ? (
            <>
              <Link 
                to="/profile" 
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                onClick={toggleMenu}
              >
                Min Profil
              </Link>
              <button 
                onClick={() => {
                  logout({ returnTo: window.location.origin });
                  toggleMenu();
                }}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Log ud
              </button>
            </>
          ) : (
            <button 
              onClick={() => {
                loginWithRedirect();
                toggleMenu();
              }}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Login
            </button>
          )}
        </div>
      )}

      {/* Cart Menu */}
      <CartMenu isOpen={isCartOpen} onClose={toggleCart} />
    </div>
  );
}

export default TopRight;