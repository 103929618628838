// src/services/productService.js
import sanityClient from './sanityClient';

export const fetchProducts = async () => {
  const query = `*[_type == "product"]{
    _id,
    name,
    price,
    "imageUrl": featuredImage.asset->url
  }`;
  try {
    return await sanityClient.fetch(query);
  } catch (error) {
    console.error('Sanity query error:', error);
    throw new Error(`Failed to fetch products: ${error.message}`);
  }
};

export const fetchProductById = async (id) => {
  const query = `*[_type == "product" && _id == $id][0]{
    _id,
    name,
    price,
    description,
    "images": [featuredImage.asset->url, ...images[].asset->url],
    "category": category->name,
    specifications
  }`;
  try {
    return await sanityClient.fetch(query, { id });
  } catch (error) {
    console.error('Sanity query error:', error);
    throw new Error(`Failed to fetch product: ${error.message}`);
  }
};