// src/components/Product/ProductList.js
import React, { useState, useEffect } from 'react';
import { fetchProducts } from '../../services/productService';
import ProductCard from './ProductCard';

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const loadProducts = async () => {
        try {
          const fetchedProducts = await fetchProducts();
          setProducts(fetchedProducts);
        } catch (err) {
          console.error('Error fetching products:', err);
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
      loadProducts();
    }, []);
  
    if (loading) {
      return <div className="text-center py-10">Indlæser produkter...</div>;
    }
  
    if (error) {
      return <div className="text-center py-10 text-red-500">Error: {error}</div>;
    }
  
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        {products.map(product => (
          <ProductCard key={product._id} product={product} />
        ))}
      </div>
    );
  };
  
  export default ProductList;