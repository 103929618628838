import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import logo from '../../assets/images/logo.png';

function SideNavigation() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative h-full">
      {/* Toggle button with new design */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="h-full px-4 flex items-center z-50 relative"
      >
        <div className="relative">
          {/* Background circle */}
          <div className="absolute inset-0 bg-light-green-50 rounded-full scale-125"></div>
          {/* White circle with icon */}
          <div className="relative bg-white p-2 rounded-full shadow-lg hover:shadow-xl transition-shadow duration-300">
            {isOpen ? (
              <XMarkIcon className="h-6 w-6 md:h-8 md:w-8 text-primary-green" />
            ) : (
              <Bars3Icon className="h-6 w-6 md:h-8 md:w-8 text-primary-green" />
            )}
          </div>
        </div>
      </button>

      {/* Sidebar - Adjusted positioning and height */}
      <div
        className={`fixed top-24 left-0 w-48 md:w-64 bg-light-green-50 rounded-tr-3xl rounded-br-3xl p-4 md:p-6 transition-all duration-300 ease-in-out transform ${
          isOpen ? 'translate-x-0 shadow-[4px_0_20px_rgba(0,0,0,0.3)]' : '-translate-x-full shadow-none'
        } flex flex-col z-50`}
        style={{ height: 'calc(85vh - 6rem)' }}
      >
        {/* Logo */}
        <div className="mb-4 md:mb-6 flex justify-center">
          <img 
            src={logo} 
            alt="Logo" 
            className="w-full max-w-[100px] md:max-w-[120px] h-auto"
          />
        </div>

        {/* Main Navigation Links - Adjusted spacing */}
        <nav className="flex flex-col space-y-3 md:space-y-4 mb-auto">
          <NavLink 
            to="/" 
            className={({ isActive }) => 
              `text-base md:text-lg font-semibold px-3 md:px-4 py-2 rounded-lg transition-colors duration-200 ${
                isActive 
                  ? "bg-green-button-color text-white" 
                  : "text-primary-green hover:bg-green-200"
              }`
            }
          >
            Forside
          </NavLink>
          <NavLink 
            to="/shop" 
            className={({ isActive }) => 
              `text-base md:text-lg font-semibold px-3 md:px-4 py-2 rounded-lg transition-colors duration-200 ${
                isActive 
                  ? "bg-green-button-color text-white" 
                  : "text-primary-green hover:bg-green-200"
              }`
            }
          >
            Webshop
          </NavLink>
          <NavLink 
            to="/blog" 
            className={({ isActive }) => 
              `text-base md:text-lg font-semibold px-3 md:px-4 py-2 rounded-lg transition-colors duration-200 ${
                isActive 
                  ? "bg-green-button-color text-white" 
                  : "text-primary-green hover:bg-green-200"
              }`
            }
          >
            Blog
          </NavLink>
          <NavLink 
            to="/user-posts" 
            className={({ isActive }) => 
              `text-base md:text-lg font-semibold px-3 md:px-4 py-2 rounded-lg transition-colors duration-200 ${
                isActive 
                  ? "bg-green-button-color text-white" 
                  : "text-primary-green hover:bg-green-200"
              }`
            }
          >
            Opslag
          </NavLink>
        </nav>

        {/* Additional Links - Adjusted spacing */}
        <div className="mt-2 md:mt-3 pt-2 md:pt-3 border-t border-primary-green">
          <nav className="flex flex-col space-y-1">
            <Link to="/betingelser" className="text-xs md:text-sm text-primary-green hover:text-green-button-color transition-colors duration-200">
              Betingelser
            </Link>
            <Link to="/privatlivspolitik" className="text-xs md:text-sm text-primary-green hover:text-green-button-color transition-colors duration-200">
              Privatlivspolitik
            </Link>
            <Link to="/om-os" className="text-xs md:text-sm text-primary-green hover:text-green-button-color transition-colors duration-200">
              Om os
            </Link>
            <Link to="/kontakt" className="text-xs md:text-sm text-primary-green hover:text-green-button-color transition-colors duration-200">
              Kontakt
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default SideNavigation;