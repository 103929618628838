// src/pages/ProductDetailPage.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchProductById } from '../services/productService';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { useCart } from '../contexts/CartContext';

const ProductDetailPage = () => {
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isEnlarged, setIsEnlarged] = useState(false);
  const { id } = useParams();
  const { dispatch } = useCart();

  useEffect(() => {
    const loadProduct = async () => {
      try {
        const fetchedProduct = await fetchProductById(id);
        setProduct(fetchedProduct);
      } catch (err) {
        console.error('Error fetching product:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    loadProduct();
  }, [id]);

  const handleAddToCart = () => {
    if (product) {
      dispatch({ 
        type: 'ADD_ITEM', 
        payload: {
          id: product._id,
          name: product.name,
          price: product.price,
          imageUrl: product.images[0] // Using the first image as the main image
        }
      });
    }
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => 
      (prevIndex + 1) % product.images.length
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => 
      (prevIndex - 1 + product.images.length) % product.images.length
    );
  };

  const handleThumbnailClick = (index) => {
    setCurrentImageIndex(index);
  };

  const toggleEnlarged = () => {
    setIsEnlarged(!isEnlarged);
  };

  if (loading) {
    return <div className="text-center py-10">Indlæser produktdetaljer...</div>;
  }

  if (error) {
    return <div className="text-center py-10 text-red-500">Fejl: {error}</div>;
  }

  if (!product) {
    return <div className="text-center py-10">Produktet blev ikke fundet.</div>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Left Side - Image Gallery */}
          <div className="md:w-1/2">
            <div className="relative">
              <img 
                src={product.images[currentImageIndex]} 
                alt={product.name} 
                className="w-full h-[500px] object-cover rounded-lg cursor-pointer"
                onClick={toggleEnlarged}
              />
              <button 
                onClick={handlePrevImage}
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md"
              >
                <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
              </button>
              <button 
                onClick={handleNextImage}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md"
              >
                <ChevronRightIcon className="h-6 w-6 text-gray-600" />
              </button>
            </div>
            <div className="mt-4 flex space-x-2 overflow-x-auto">
              {product.images.map((image, index) => (
                <img 
                  key={index}
                  src={image} 
                  alt={`${product.name} thumbnail ${index + 1}`}
                  className={`w-20 h-20 object-cover rounded-md cursor-pointer ${
                    index === currentImageIndex ? 'border-2 border-primary-green' : ''
                  }`}
                  onClick={() => handleThumbnailClick(index)}
                />
              ))}
            </div>
          </div>

          {/* Right Side - Product Details */}
          <div className="md:w-1/2">
            <h1 className="text-3xl font-bold text-primary-green mb-4">{product.name}</h1>
            <p className="text-gray-600 text-lg mb-4">{product.category}</p>
            <p className="text-forest-green font-bold text-3xl mb-6">{product.price} DKK</p>
            
            <div className="mb-6">
              <h2 className="text-xl font-semibold text-primary-green mb-2">Beskrivelse</h2>
              <p className="text-gray-700">{product.description}</p>
            </div>
            
            {product.specifications && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold text-primary-green mb-2">Specifikationer</h2>
                <ul className="list-disc list-inside text-gray-700">
                  {product.specifications.map((spec, index) => (
                    <li key={index}>{spec}</li>
                  ))}
                </ul>
              </div>
            )}
            
            <button 
              onClick={handleAddToCart}
              className="w-full bg-green-button-color text-white px-6 py-3 rounded-lg hover:bg-opacity-90 transition-colors duration-200 text-xl font-semibold"
            >
              Læg i kurv
            </button>
          </div>
        </div>
      </div>

      {/* Enlarged Image Modal */}
      {isEnlarged && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={toggleEnlarged}>
          <img 
            src={product.images[currentImageIndex]} 
            alt={product.name} 
            className="max-w-full max-h-full object-contain"
          />
        </div>
      )}
    </div>
  );
};

export default ProductDetailPage;