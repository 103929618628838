import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ProductCarousel from '../components/Product/ProductCarousel';
import { fetchBlogPosts } from '../services/blogService';
import BlogNoteCard from '../components/Blog/BlogNoteCard';

function HomePage() {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const loadBlogPosts = async () => {
      try {
        const posts = await fetchBlogPosts(4);  // Fetch only the last 4 posts
        setBlogPosts(posts);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };
    loadBlogPosts();
  }, []);


  return (
    <div className="container mx-auto px-4">
      {/* Added extra space above the welcome section */}
      <div className="h-64"></div>
      
      {/* Velkommen sektion */}
      <section className="text-center mb-16">
        <h2 className="text-4xl font-bold text-primary-green mb-4">Velkommen til We Love Birds</h2>
        <p className="text-lg text-gray-700 mb-8">Udforsk vores verden af fugle, lær om forskellige arter, og del din passion med andre fugleentusiaster.</p>
        <Link to="/shop" className="bg-green-button-color text-white px-6 py-3 rounded-lg hover:bg-opacity-90 transition-colors duration-200 text-xl font-bold">
          Besøg vores butik
        </Link>
      </section>

      {/* Product Carousel section */}
      <section className="mb-16">
        <h3 className="text-3xl font-semibold text-primary-green mb-6">Udvalgte produkter</h3>
        <ProductCarousel />
      </section>

     {/* Seneste fra bloggen */}
     <section className="mb-16">
        <h3 className="text-3xl font-semibold text-primary-green mb-6">Seneste fra bloggen</h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-3 sm:gap-4 md:gap-6">
          {blogPosts.map((post) => (
            <BlogNoteCard key={post._id} post={post} />
          ))}
        </div>
      </section>

      {/* Brugeropslag */}
      <section className="mb-16">
        <h3 className="text-3xl font-semibold text-primary-green mb-6">Seneste brugeropslag</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {['Spurvehøg spottet i Dyrehaven', 'Min nye fuglefoderbræt er en succes!', 'Hjælp til identifikation af denne fugl?'].map((opslag) => (
            <div key={opslag} className="bg-light-green-50 p-4 rounded-lg shadow-md">
              <p className="text-gray-800 mb-2">{opslag}</p>
              <span className="text-sm text-gray-600">Opslået af FugleVen123</span>
            </div>
          ))}
        </div>
        <div className="text-center mt-8">
          <Link to="/user-posts" className="text-green-button-color hover:underline text-lg">Se alle brugeropslag</Link>
        </div>
      </section>

      {/* Tilmeld nyhedsbrev */}
      <section className="bg-forest-green text-white p-8 rounded-lg mb-16">
        <h3 className="text-3xl font-semibold mb-4">Tilmeld dig vores nyhedsbrev</h3>
        <p className="mb-6">Få de seneste nyheder om fugle, tips til fuglekiggeri, og eksklusive tilbud direkte i din indbakke.</p>
        <form className="flex flex-col md:flex-row gap-4">
          <input type="email" placeholder="Din e-mail" className="flex-grow px-4 py-2 rounded-lg text-gray-800" />
          <button type="submit" className="bg-sage text-primary-green px-6 py-2 rounded-lg hover:bg-opacity-90 transition-colors duration-200 font-semibold">Tilmeld</button>
        </form>
      </section>
    </div>
  );
}

export default HomePage;