import React from 'react';
import { Link } from 'react-router-dom';

const BlogNoteCard = ({ post }) => {
  // Function to generate a random rotation between -2 and 2 degrees
  const randomRotation = () => Math.random() * 4 - 2;

  return (
    <Link to={`/blog/${post.slug.current}`} className="block transform transition-all duration-300 hover:scale-105">
      <div 
        className="bg-white p-2 sm:p-3 md:p-4 rounded-lg shadow-lg relative overflow-visible"
        style={{ 
          transform: `rotate(${randomRotation()}deg)`,
          transformOrigin: 'center',
        }}
      >
        <div 
          className="absolute -top-2 sm:-top-3 md:-top-4 left-1/2 transform -translate-x-1/2 w-4 sm:w-6 md:w-8 h-4 sm:h-6 md:h-8 bg-sage rounded-full z-10 shadow-md transition-shadow duration-300"
          style={{
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
          }}
        ></div>
        <div className="relative pt-2 sm:pt-3 md:pt-4">
          <img 
            src={post.imageUrl} 
            alt={post.title}
            className="w-full h-24 sm:h-32 md:h-40 lg:h-48 object-cover rounded-t-lg mb-2 sm:mb-3 md:mb-4"
          />
          <h4 className="text-sm sm:text-base md:text-lg font-semibold text-primary-green line-clamp-2">{post.title}</h4>
        </div>
      </div>
    </Link>
  );
};

export default BlogNoteCard;