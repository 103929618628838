// src/pages/BlogPostPage.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BlogPost from '../components/Blog/BlogPost';
import { fetchBlogPost } from '../services/blogService';

function BlogPostPage() {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    const loadPost = async () => {
      try {
        const fetchedPost = await fetchBlogPost(slug);
        setPost(fetchedPost);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching blog post:', err);
        setError(err.message);
        setLoading(false);
      }
    };
    loadPost();
  }, [slug]);

  if (loading) return <div className="text-center py-10">Loading...</div>;
  if (error) return <div className="text-center py-10 text-red-500">Error: {error}</div>;

  return (
    <div className="min-h-screen py-8 px-4 sm:px-6 lg:px-8">
      <BlogPost post={post} />
    </div>
  );
}

export default BlogPostPage;