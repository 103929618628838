import React, { useState } from 'react';
import { useCart } from '../contexts/CartContext';
import { ChevronRightIcon, CreditCardIcon, DevicePhoneMobileIcon } from '@heroicons/react/24/outline';
import { ApplePayIcon, GooglePayIcon } from '../components/Icons';

const CheckoutPage = () => {
  const { state: cartState } = useCart();
  const [step, setStep] = useState(1);
  const [shippingInfo, setShippingInfo] = useState({
    fullName: '',
    address: '',
    city: '',
    postalCode: '',
    country: 'Danmark',
  });
  const [paymentMethod, setPaymentMethod] = useState('');

  const totalPrice = cartState.items.reduce((total, item) => total + item.price * item.quantity, 0);
  const shippingCost = 39; // Example shipping cost
  const totalWithShipping = totalPrice + shippingCost;

  const handleShippingInfoChange = (e) => {
    setShippingInfo({ ...shippingInfo, [e.target.name]: e.target.value });
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handlePaymentMethodSelect = (method) => {
    setPaymentMethod(method);
  };

  const renderStepIndicator = () => (
    <div className="flex justify-center mb-8">
      {[1, 2, 3].map((stepNumber) => (
        <div key={stepNumber} className="flex items-center">
          <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
            step >= stepNumber ? 'bg-green-button-color text-white' : 'bg-gray-200 text-gray-600'
          }`}>
            {stepNumber}
          </div>
          {stepNumber < 3 && (
            <div className={`w-16 h-1 ${step > stepNumber ? 'bg-green-button-color' : 'bg-gray-200'}`} />
          )}
        </div>
      ))}
    </div>
  );

  const renderCartSummary = () => (
    <div className="bg-light-green-50 p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-primary-green mb-4">Ordreoversigt</h2>
      {cartState.items.map((item) => (
        <div key={item.id} className="flex justify-between items-center mb-2">
          <span>{item.name} x {item.quantity}</span>
          <span>{(item.price * item.quantity).toFixed(2)} DKK</span>
        </div>
      ))}
      <div className="border-t border-gray-300 my-4 pt-4">
        <div className="flex justify-between items-center mb-2">
          <span>Subtotal</span>
          <span>{totalPrice.toFixed(2)} DKK</span>
        </div>
        <div className="flex justify-between items-center mb-2">
          <span>Forsendelse</span>
          <span>{shippingCost.toFixed(2)} DKK</span>
        </div>
        <div className="flex justify-between items-center font-semibold text-lg mt-4">
          <span>Total</span>
          <span>{totalWithShipping.toFixed(2)} DKK</span>
        </div>
      </div>
    </div>
  );

  const renderShippingForm = () => (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-primary-green mb-4">Leveringsoplysninger</h2>
      <form onSubmit={(e) => { e.preventDefault(); handleNextStep(); }}>
        <div className="mb-4">
          <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">Fulde navn</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={shippingInfo.fullName}
            onChange={handleShippingInfoChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-button-color focus:ring focus:ring-green-button-color focus:ring-opacity-50"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="address" className="block text-sm font-medium text-gray-700">Adresse</label>
          <input
            type="text"
            id="address"
            name="address"
            value={shippingInfo.address}
            onChange={handleShippingInfoChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-button-color focus:ring focus:ring-green-button-color focus:ring-opacity-50"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="city" className="block text-sm font-medium text-gray-700">By</label>
          <input
            type="text"
            id="city"
            name="city"
            value={shippingInfo.city}
            onChange={handleShippingInfoChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-button-color focus:ring focus:ring-green-button-color focus:ring-opacity-50"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="postalCode" className="block text-sm font-medium text-gray-700">Postnummer</label>
          <input
            type="text"
            id="postalCode"
            name="postalCode"
            value={shippingInfo.postalCode}
            onChange={handleShippingInfoChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-button-color focus:ring focus:ring-green-button-color focus:ring-opacity-50"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="country" className="block text-sm font-medium text-gray-700">Land</label>
          <select
            id="country"
            name="country"
            value={shippingInfo.country}
            onChange={handleShippingInfoChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-button-color focus:ring focus:ring-green-button-color focus:ring-opacity-50"
          >
            <option value="Danmark">Danmark</option>
            {/* Add more countries as needed */}
          </select>
        </div>
        <button
          type="submit"
          className="w-full bg-green-button-color text-white px-6 py-3 rounded-lg hover:bg-opacity-90 transition-colors duration-200 text-lg font-semibold flex items-center justify-center"
        >
          Fortsæt til betaling
          <ChevronRightIcon className="h-5 w-5 ml-2" />
        </button>
      </form>
    </div>
  );

  const renderPaymentMethods = () => (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-primary-green mb-4">Vælg betalingsmetode</h2>
      <div className="space-y-4">
        <button
          onClick={() => handlePaymentMethodSelect('credit-card')}
          className={`w-full p-4 rounded-lg border-2 flex items-center ${
            paymentMethod === 'credit-card' ? 'border-green-button-color' : 'border-gray-300'
          }`}
        >
          <CreditCardIcon className="h-8 w-8 mr-4 text-primary-green" />
          <span className="text-lg">Kreditkort</span>
        </button>
        <button
          onClick={() => handlePaymentMethodSelect('mobile-pay')}
          className={`w-full p-4 rounded-lg border-2 flex items-center ${
            paymentMethod === 'mobile-pay' ? 'border-green-button-color' : 'border-gray-300'
          }`}
        >
          <DevicePhoneMobileIcon className="h-8 w-8 mr-4 text-primary-green" />
          <span className="text-lg">MobilePay</span>
        </button>
        <button
          onClick={() => handlePaymentMethodSelect('apple-pay')}
          className={`w-full p-4 rounded-lg border-2 flex items-center ${
            paymentMethod === 'apple-pay' ? 'border-green-button-color' : 'border-gray-300'
          }`}
        >
          <ApplePayIcon className="h-8 w-8 mr-4" />
          <span className="text-lg">Apple Pay</span>
        </button>
        <button
          onClick={() => handlePaymentMethodSelect('google-pay')}
          className={`w-full p-4 rounded-lg border-2 flex items-center ${
            paymentMethod === 'google-pay' ? 'border-green-button-color' : 'border-gray-300'
          }`}
        >
          <GooglePayIcon className="h-8 w-8 mr-4" />
          <span className="text-lg">Google Pay</span>
        </button>
      </div>
      <div className="mt-6 flex justify-between">
        <button
          onClick={handlePreviousStep}
          className="bg-gray-200 text-gray-800 px-6 py-3 rounded-lg hover:bg-gray-300 transition-colors duration-200 text-lg font-semibold"
        >
          Tilbage
        </button>
        <button
          onClick={handleNextStep}
          disabled={!paymentMethod}
          className={`bg-green-button-color text-white px-6 py-3 rounded-lg hover:bg-opacity-90 transition-colors duration-200 text-lg font-semibold ${
            !paymentMethod && 'opacity-50 cursor-not-allowed'
          }`}
        >
          Fortsæt
        </button>
      </div>
    </div>
  );

  const renderConfirmation = () => (
    <div className="bg-white p-6 rounded-lg shadow-md text-center">
      <h2 className="text-2xl font-semibold text-primary-green mb-4">Bekræft din ordre</h2>
      <p className="mb-4">Gennemgå venligst din ordre og leveringsoplysninger før du gennemfører købet.</p>
      <div className="mb-6">
        <h3 className="font-semibold mb-2">Leveringsadresse:</h3>
        <p>{shippingInfo.fullName}</p>
        <p>{shippingInfo.address}</p>
        <p>{shippingInfo.postalCode}, {shippingInfo.city}</p>
        <p>{shippingInfo.country}</p>
      </div>
      <div className="mb-6">
        <h3 className="font-semibold mb-2">Betalingsmetode:</h3>
        <p>{paymentMethod === 'credit-card' ? 'Kreditkort' :
           paymentMethod === 'mobile-pay' ? 'MobilePay' :
           paymentMethod === 'apple-pay' ? 'Apple Pay' :
           paymentMethod === 'google-pay' ? 'Google Pay' : ''}</p>
      </div>
      <div className="mt-6 flex justify-between">
        <button
          onClick={handlePreviousStep}
          className="bg-gray-200 text-gray-800 px-6 py-3 rounded-lg hover:bg-gray-300 transition-colors duration-200 text-lg font-semibold"
        >
          Tilbage
        </button>
        <button
          onClick={() => {/* Handle order submission */}}
          className="bg-green-button-color text-white px-6 py-3 rounded-lg hover:bg-opacity-90 transition-colors duration-200 text-lg font-semibold"
        >
          Gennemfør køb
        </button>
      </div>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-primary-green mb-8 text-center">Checkout</h1>
      {renderStepIndicator()}
      <div className="grid md:grid-cols-2 gap-8">
        <div>
          {step === 1 && renderShippingForm()}
          {step === 2 && renderPaymentMethods()}
          {step === 3 && renderConfirmation()}
        </div>
        <div>
          {renderCartSummary()}
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;