// src/components/Product/ProductCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../../contexts/CartContext';

const ProductCard = ({ product }) => {
  const { dispatch } = useCart();

  const handleAddToCart = () => {
    dispatch({ 
      type: 'ADD_ITEM', 
      payload: {
        id: product._id,
        name: product.name,
        price: product.price,
        imageUrl: product.imageUrl
      }
    });
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:shadow-xl hover:-translate-y-1 flex flex-col h-full">
      <div className="p-4 flex-grow">
        <img 
          src={product.imageUrl} 
          alt={product.name} 
          className="w-full h-80 object-cover rounded-lg"
        />
      </div>
      <div className="p-6 flex flex-col justify-between flex-grow">
        <div>
          <h3 className="text-2xl font-semibold text-primary-green mb-3">{product.name}</h3>
          <p className="text-gray-600 text-lg mb-4">{product.category}</p>
          <p className="text-forest-green font-bold text-3xl mb-6">{product.price} DKK</p>
        </div>
        <div className="flex flex-col space-y-2">
          <button 
            onClick={handleAddToCart}
            className="bg-green-button-color text-white px-6 py-4 rounded-lg hover:bg-opacity-90 transition-colors duration-200 text-xl font-semibold"
          >
            Læg i kurv
          </button>
          <Link 
            to={`/product/${product._id}`} 
            className="block text-center bg-white border-2 border-green-button-color text-green-button-color px-6 py-4 rounded-lg hover:bg-green-button-color hover:text-white transition-colors duration-200 text-xl font-semibold"
          >
            Se produkt
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;