// src/pages/UserPostsPage.js
import React, { useState, useEffect } from 'react';
import { fetchUserPosts } from '../services/userPostService';
import UserPostCard from '../components/UserPosts/UserPostCard';

function UserPostsPage() {
    const [userPosts, setUserPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const loadUserPosts = async () => {
        try {
          const posts = await fetchUserPosts();
          setUserPosts(posts || []); // Ensure we always set an array
          setLoading(false);
        } catch (err) {
          console.error('Error fetching user posts:', err);
          setError(err.message);
          setLoading(false);
        }
      };
      loadUserPosts();
    }, []);
  
    if (loading) {
      return <div className="text-center py-10">Indlæser brugeropslag...</div>;
    }
  
    if (error) {
      return <div className="text-center py-10 text-red-500">Fejl: {error}</div>;
    }
  
    if (userPosts.length === 0) {
      return <div className="text-center py-10">Ingen brugeropslag fundet.</div>;
    }
  
    return (
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-primary-green mb-8 text-center">Undulat Markedsplads</h1>
        <div className="space-y-8">
          {userPosts.map((post) => (
            <UserPostCard key={post._id} post={post} />
          ))}
        </div>
      </div>
    );
  }
  
  export default UserPostsPage;