// src/services/userPostService.js
import sanityClient from './sanityClient';

export const fetchUserPosts = async () => {
  const query = `*[_type == "userPost"] | order(createdAt desc) {
    _id,
    title,
    description,
    "featuredImageUrl": featuredImage.asset->url,
    "images": images[].asset->url,
    price,
    contactInfo,
    createdAt
  }`;
  
  try {
    const result = await sanityClient.fetch(query);
    console.log('Fetched user posts:', result);
    return result;
  } catch (error) {
    console.error('Error fetching user posts:', error);
    throw new Error('Failed to fetch user posts');
  }
};

export const fetchUserPostsByUserId = async (userId) => {
  const query = `*[_type == "userPost" && userId == $userId] | order(createdAt desc) {
    _id,
    title,
    description,
    "featuredImageUrl": featuredImage.asset->url,
    "images": images[].asset->url,
    price,
    contactInfo,
    createdAt
  }`;
  
  try {
    const result = await sanityClient.fetch(query, { userId });
    console.log('Fetched user posts for user:', result);
    return result;
  } catch (error) {
    console.error('Error fetching user posts for user:', error);
    throw new Error('Failed to fetch user posts');
  }
};