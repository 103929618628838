// src/pages/ShopPage.js
import React from 'react';
import ProductList from '../components/Product/ProductList';

function ShopPage() {
  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-primary-green mb-8 text-center">Vores Produkter</h1>
      <div className="bg-light-green-50 rounded-lg shadow-lg p-6 md:p-8">
        <ProductList />
      </div>
    </div>
  );
}

export default ShopPage;