// src/components/UserPost/UserPostCard.js
import React, { useState, useEffect, useMemo } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { UserIcon, MapPinIcon, PhoneIcon, EnvelopeIcon, CalendarIcon } from '@heroicons/react/24/outline';
import intetBillede from '../../assets/images/intetbillede.png';
import BlockContent from '@sanity/block-content-to-react';

const UserPostCard = ({ post }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showContact, setShowContact] = useState(false);

  const { 
    title = '', 
    featuredImageUrl, 
    images = [], 
    description = '', 
    price = '', 
    contactInfo = {}, 
    createdAt 
  } = post || {};

  const allImages = [featuredImageUrl, ...images].filter(Boolean);

  useEffect(() => {
    if (allImages.length === 0) {
      allImages.push(intetBillede);
    }
  }, [allImages]);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => 
      prevIndex === allImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => 
      prevIndex === 0 ? allImages.length - 1 : prevIndex - 1
    );
  };

  const toggleContact = () => {
    setShowContact(!showContact);
  };

  // Generate a random rotation between -3 and 3 degrees
  const randomRotation = useMemo(() => Math.random() * 6 - 3, []);

  if (!post) return null;

  return (
    <div className="relative mt-6">
      {/* Magnet */}
      <div 
        className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 bg-[#DEF9DD] rounded-full z-10"
        style={{
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      ></div>
      
      {/* Card */}
      <div 
        className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col relative"
        style={{
          border: '10px solid #E6F4EA', // Light green border
          transform: `rotate(${randomRotation}deg)`,
          transition: 'transform 0.3s ease-in-out',
        }}
      >
        <div className="flex flex-col md:flex-row flex-grow">
          {/* Left side - Images */}
          <div className="md:w-1/2 p-4 relative">
            <div className="relative">
              <img 
                src={allImages[currentImageIndex]} 
                alt={title} 
                className="w-full h-96 object-cover rounded-lg"
              />
              <button 
                onClick={prevImage} 
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md"
              >
                <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
              </button>
              <button 
                onClick={nextImage} 
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md"
              >
                <ChevronRightIcon className="h-6 w-6 text-gray-600" />
              </button>
            </div>
            <div className="flex overflow-x-auto space-x-2 mt-4">
              {allImages.map((image, index) => (
                <img 
                  key={index}
                  src={image} 
                  alt={`${title} - ${index + 1}`} 
                  className={`w-24 h-24 object-cover rounded-md cursor-pointer ${
                    index === currentImageIndex ? 'border-2 border-primary-green' : ''
                  }`}
                  onClick={() => setCurrentImageIndex(index)}
                />
              ))}
            </div>
          </div>

          {/* Right side - Information */}
          <div className="md:w-1/2 p-6 flex flex-col">
            <h2 className="text-3xl font-bold text-primary-green mb-4">{title}</h2>
            <div className="overflow-y-auto flex-grow mb-4" style={{maxHeight: '300px'}}>
              <BlockContent blocks={description} />
            </div>
            <p className="text-2xl font-bold text-forest-green mb-4">{price}</p>
            <button
              onClick={toggleContact}
              className="bg-green-button-color text-white px-6 py-3 rounded-lg hover:bg-opacity-90 transition-colors duration-200 text-lg font-semibold"
            >
              {showContact ? 'Skjul kontakt' : 'Vis kontakt'}
            </button>
          </div>
        </div>

        {/* Contact information and creation date */}
        {showContact && (
          <div className="bg-light-green-50 p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <h3 className="text-xl font-semibold text-primary-green mb-2 col-span-full">Kontaktoplysninger</h3>
              <div className="flex items-center">
                <UserIcon className="h-5 w-5 text-primary-green mr-2" />
                <p>{contactInfo.name}</p>
              </div>
              <div className="flex items-center">
                <MapPinIcon className="h-5 w-5 text-primary-green mr-2" />
                <p>{contactInfo.city}, {contactInfo.zipCode}</p>
              </div>
              <div className="flex items-center">
                <PhoneIcon className="h-5 w-5 text-primary-green mr-2" />
                <p>{contactInfo.phone}</p>
              </div>
              <div className="flex items-center">
                <EnvelopeIcon className="h-5 w-5 text-primary-green mr-2" />
                <p>{contactInfo.email}</p>
              </div>
            </div>
            {createdAt && (
              <div className="flex items-center text-sm text-gray-600">
                <CalendarIcon className="h-4 w-4 mr-2" />
                Oprettet den: {new Date(createdAt).toLocaleDateString()}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserPostCard;