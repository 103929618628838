import React from 'react';
import { Link } from 'react-router-dom';
import SideNavigation from './SideNavigation';
import TopRight from './TopRight';
import logo from '../../assets/images/logo.png';

function Header() {
  return (
    <header className="fixed top-0 left-0 right-0 z-30">
      <div className="relative flex justify-between items-start px-4 py-4">
        {/* Side Navigation and Logo */}
        <div className="flex items-center">
          <div className="z-50">
            <SideNavigation />
          </div>
          <Link to="/" className="ml-2 sm:ml-4 group z-40">
            <img 
              src={logo} 
              alt="We Love Birds Logo" 
              className="h-12 sm:h-16 md:h-20 lg:h-24 transition-all duration-300 transform group-hover:-translate-y-1 group-hover:drop-shadow-lg"
            />
          </Link>
        </div>

        {/* Centered Title */}
        <div className="absolute top-0 left-0 right-0 flex justify-center items-center pointer-events-none z-30">
          <h1 className="font-inspiration font-extralight text-black text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl whitespace-nowrap mt-6">
            We Love Birds
          </h1>
        </div>

        {/* Top Right Component */}
        <div className="w-1/4 md:w-80 z-40 flex justify-end">
          <TopRight />
        </div>
      </div>
    </header>
  );
}

export default Header;