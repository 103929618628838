import React from 'react';
import applePayImage from '../assets/images/applepay128.png';
import googlePayImage from '../assets/images/googlepay128.png';

export const ApplePayIcon = ({ className }) => (
  <img src={applePayImage} alt="Apple Pay" className={className} />
);

export const GooglePayIcon = ({ className }) => (
  <img src={googlePayImage} alt="Google Pay" className={className} />
);