// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/common/Header';
import HomePage from './pages/HomePage';
import ShopPage from './pages/ShopPage';
import BlogPage from './pages/BlogPage';
import BlogPostPage from './pages/BlogPostPage';
import UserPostsPage from './pages/UserPostsPage';
import ProductDetailPage from './pages/ProductDetailPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ProfilePage from './pages/ProfilePage';  // Add this import
import backgroundImage from './assets/images/backgroundimage.jpg';
import { CartProvider } from './contexts/CartContext';
import CheckoutPage from './pages/CheckoutPage';

function App() {
  return (
    <CartProvider>
    <Router>
      <div 
        className="min-h-screen bg-cover bg-fixed"
        style={{ 
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: 'center top'
        }}
      >
        <Header />
        <main className="container mx-auto p-4 pt-40">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/shop" element={<ShopPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:slug" element={<BlogPostPage />} />
            <Route path="/user-posts" element={<UserPostsPage />} />
            <Route path="/product/:id" element={<ProductDetailPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/profile" element={<ProfilePage />} />  {/* Add this line */}
            <Route path="/checkout" element={<CheckoutPage />} />
          </Routes>
        </main>
      </div>
    </Router>
    </CartProvider>
  );
}

export default App;