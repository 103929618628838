import React, { useState, useEffect } from 'react';
import { fetchPurchaseHistory } from '../../services/purchaseService';

const PurchaseHistory = ({ userId }) => {
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadPurchases = async () => {
      try {
        const history = await fetchPurchaseHistory(userId);
        setPurchases(history);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching purchase history:', err);
        setError(err.message);
        setLoading(false);
      }
    };
    loadPurchases();
  }, [userId]);

  if (loading) return <div>Loading purchase history...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-semibold text-primary-green mb-4">Købshistorik</h2>
      {purchases.length === 0 ? (
        <p>Du har endnu ikke foretaget nogen køb.</p>
      ) : (
        <ul>
          {purchases.map(purchase => (
            <li key={purchase._id} className="mb-4 pb-4 border-b">
              <h3 className="text-xl font-semibold">Ordre #{purchase.orderNumber}</h3>
              <p className="text-gray-600">Dato: {new Date(purchase.date).toLocaleDateString()}</p>
              <p className="text-gray-600">Total: {purchase.total} DKK</p>
              <a href={purchase.receiptUrl} target="_blank" rel="noopener noreferrer" className="text-green-button-color hover:underline">
                Se kvittering
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PurchaseHistory;