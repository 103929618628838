import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const DeleteAccount = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { logout } = useAuth0();

  const handleDeleteAccount = async () => {
    // Here you would typically call an API to delete the user's account
    // For this example, we'll just log out the user
    console.log('Account deletion would happen here');
    logout({ returnTo: window.location.origin });
  };

  return (
    <div>
      {!showConfirmation ? (
        <button
          onClick={() => setShowConfirmation(true)}
          className="border-2 border-red-500 text-red-500 px-4 py-2 rounded-lg hover:bg-red-500 hover:text-white transition-colors duration-200 flex items-center"
        >
          <ExclamationTriangleIcon className="h-5 w-5 mr-2" />
          Slet min konto
        </button>
      ) : (
        <div className="bg-red-100 border border-red-400 text-red-700 px-6 py-4 rounded-lg relative" role="alert">
          <div className="flex items-center mb-4">
            <ExclamationTriangleIcon className="h-6 w-6 mr-2" />
            <strong className="font-bold text-lg">Advarsel!</strong>
          </div>
          <p className="mb-4">Er du sikker på, at du vil slette din konto? Dette kan ikke fortrydes.</p>
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => setShowConfirmation(false)}
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-400 transition-colors duration-200"
            >
              Annuller
            </button>
            <button
              onClick={handleDeleteAccount}
              className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors duration-200"
            >
              Ja, slet min konto
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteAccount;