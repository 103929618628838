// src/services/purchaseService.js
import sanityClient from './sanityClient';

export const fetchPurchaseHistory = async (userId) => {
  const query = `*[_type == "purchase" && userId == $userId] | order(date desc) {
    _id,
    orderNumber,
    date,
    total,
    "items": items[]{
      "product": product->{ name, "imageUrl": image.asset->url },
      quantity,
      price
    },
    "receiptUrl": receipt.asset->url
  }`;
  
  try {
    const result = await sanityClient.fetch(query, { userId });
    console.log('Fetched purchase history:', result); // For debugging
    return result;
  } catch (error) {
    console.error('Error fetching purchase history:', error);
    throw new Error('Failed to fetch purchase history');
  }
};

export const createPurchase = async (purchaseData) => {
  try {
    const result = await sanityClient.create({
      _type: 'purchase',
      ...purchaseData
    });
    console.log('Created purchase:', result); // For debugging
    return result;
  } catch (error) {
    console.error('Error creating purchase:', error);
    throw new Error('Failed to create purchase');
  }
};

export const getPurchaseDetails = async (purchaseId) => {
  const query = `*[_type == "purchase" && _id == $purchaseId][0]{
    _id,
    orderNumber,
    date,
    total,
    "items": items[]{
      "product": product->{ name, "imageUrl": image.asset->url },
      quantity,
      price
    },
    "receiptUrl": receipt.asset->url
  }`;
  
  try {
    const result = await sanityClient.fetch(query, { purchaseId });
    console.log('Fetched purchase details:', result); // For debugging
    return result;
  } catch (error) {
    console.error('Error fetching purchase details:', error);
    throw new Error('Failed to fetch purchase details');
  }
};