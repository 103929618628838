import React from 'react';
import { Link } from 'react-router-dom';

const BlogPostMagnet = ({ post }) => {
  const randomRotation = Math.random() * 6 - 3; // Random rotation between -3 and 3 degrees

  return (
    <Link to={`/blog/${post.slug.current}`} className="block transform transition-all duration-300 hover:scale-105">
      <div className="relative pt-6">
        {/* Magnet */}
        <div 
          className="absolute top-0 left-1/2 transform -translate-x-1/2 w-12 h-12 bg-[#DEF9DD] rounded-full z-20"
          style={{
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
          }}
        ></div>
        
        <div 
          className="relative bg-white rounded-2xl shadow-lg overflow-hidden z-10"
          style={{ 
            transform: `rotate(${randomRotation}deg)`,
            transformOrigin: 'center',
            width: '100%',
            height: '600px',
          }}
        >
          {/* Image */}
          <img 
            src={post.imageUrl} 
            alt={post.title}
            className="w-full h-[500px] object-cover"
          />

          {/* Title - Updated background and text color */}
          <div className="bg-white p-6 h-[100px] flex items-center justify-center">
            <h2 className="text-primary-green text-2xl font-semibold text-center line-clamp-2">{post.title}</h2>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BlogPostMagnet;