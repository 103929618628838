// src/components/blog/BlogPost.js
import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import sanityClient from '../../services/sanityClient';

function BlogPost({ post }) {
  if (!post) return null;

  return (
    <article className="bg-white rounded-2xl overflow-hidden max-w-4xl mx-auto shadow-lg">
      {post.imageUrl && (
        <img 
          src={post.imageUrl} 
          alt={post.title}
          className="w-full h-64 object-cover"
        />
      )}
      <div className="px-6 sm:px-8 py-8">
        <header className="mb-8">
          <h1 className="text-4xl font-bold text-primary-green mb-4">{post.title}</h1>
          <div className="flex items-center text-gray-600 mb-4">
            {post.authorImage && (
              <img 
                src={post.authorImage}
                alt={post.name}
                className="w-10 h-10 rounded-full mr-4"
              />
            )}
            <span className="mr-4">{post.name}</span>
            <span>{new Date(post.publishedAt).toLocaleDateString()}</span>
          </div>
          {post.categories && (
            <div className="flex flex-wrap gap-2 mb-4">
              {post.categories.map(category => (
                <span key={category} className="bg-sage text-primary-green px-3 py-1 rounded-full text-sm">
                  {category}
                </span>
              ))}
            </div>
          )}
        </header>
        
        <div className="prose max-w-none">
          <BlockContent
            blocks={post.body}
            projectId={sanityClient.config().projectId}
            dataset={sanityClient.config().dataset}
          />
        </div>
      </div>
    </article>
  );
}

export default BlogPost;