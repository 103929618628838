import React, { useState, useEffect, useCallback } from 'react';
import { fetchProducts } from '../../services/productService';
import { Link } from 'react-router-dom';

const ProductCarousel = () => {
  const [products, setProducts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const fetchedProducts = await fetchProducts();
        setProducts(fetchedProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    loadProducts();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length);
  }, [products.length]);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + products.length) % products.length);
  };

  useEffect(() => {
    const intervalId = setInterval(nextSlide, 5000);
    return () => clearInterval(intervalId);
  }, [nextSlide]);

  if (products.length === 0) return null;


  return (
    <div className="bg-light-green-50 rounded-xl p-6 shadow-lg">
      <div className="relative w-full max-w-6xl mx-auto">
        <div className="overflow-hidden">
          <div 
            className="flex transition-transform duration-300 ease-in-out" 
            style={{ transform: `translateX(-${isMobile ? currentIndex * 100 : currentIndex * (100 / 3)}%)` }}
          >
            {products.map((product, index) => (
              <div key={`${product._id}-${index}`} className={`${isMobile ? 'w-full' : 'w-1/3'} flex-shrink-0 px-2`}>
                <div className="bg-white rounded-lg shadow-md p-4 m-2 flex flex-col items-center">
                  <img src={product.imageUrl} alt={product.name} className="w-36 h-36 object-cover mb-2 rounded-lg" />
                  <h3 className="text-lg font-normal mb-1 text-[#11340D]">{product.name}</h3>
                  <p className="text-[#315F2D] font-normal mb-2">{product.price} DKK</p>
                  <Link 
                    to={`/product/${product._id}`} 
                    className="bg-green-button-color text-white px-4 py-2 rounded-lg hover:bg-opacity-90 transition-colors duration-200 text-base shadow-md"
                  >
                    Se produkt
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button 
          onClick={prevSlide} 
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md z-10 ml-1"
        >
          &#8592;
        </button>
        <button 
          onClick={nextSlide} 
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md z-10 mr-1"
        >
          &#8594;
        </button>
      </div>
    </div>
  );
};

export default ProductCarousel;