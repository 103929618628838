import React, { useState, useEffect } from 'react';
import { fetchUserPostsByUserId } from '../../services/userPostService';

const UserPosts = ({ userId }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadPosts = async () => {
      try {
        const userPosts = await fetchUserPostsByUserId(userId);
        setPosts(userPosts);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching user posts:', err);
        setError(err.message);
        setLoading(false);
      }
    };
    loadPosts();
  }, [userId]);

  if (loading) return <div>Loading posts...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-semibold text-primary-green mb-4">Mine Opslag</h2>
      {posts.length === 0 ? (
        <p>Du har endnu ikke oprettet nogen opslag.</p>
      ) : (
        <ul>
          {posts.map(post => (
            <li key={post._id} className="mb-4 pb-4 border-b">
              <h3 className="text-xl font-semibold">{post.title}</h3>
              <p className="text-gray-600">{post.description}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default UserPosts;