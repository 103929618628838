import React, { useState, useEffect } from 'react';
import { fetchBlogPosts } from './../services/blogService';
import BlogPostMagnet from '../components/Blog/BlogPostMagnet';

function BlogPage() {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadBlogPosts = async () => {
      try {
        const posts = await fetchBlogPosts();
        setBlogPosts(posts);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching blog posts:', err);
        setError(err.message);
        setLoading(false);
      }
    };
    loadBlogPosts();
  }, []);

  if (loading) {
    return <div className="text-center py-10">Indlæser blog indlæg...</div>;
  }

  if (error) {
    return <div className="text-center py-10 text-red-500">Fejl: {error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-primary-green mb-20 text-center">Velkommen til bloggen</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-20">
        {blogPosts.map((post) => (
          <BlogPostMagnet key={post._id} post={post} />
        ))}
      </div>
    </div>
  );
}

export default BlogPage;